// masterCompany.js
import axios from "axios";

const state = {
  dataMasterAllDrivers: [],
  dataMasterDrivers: [],
  dataDriverDetails: [],
  dataMasterAllAssets: [],
  dataMasterAssets: [],
  dataAssignAssets: [],
  dataAssignAssetsJob: [],
  dataMasterTechnicians: [],
  dataTyreAssets: [],
  dataDriverAllocate: [],
  dataAssetsAllocate: [],
  dataAssetsTrailer: [],
  dataMasterClaimProduct: [],
  currentAssetId: null,

  // Flags to track whether data has been fetched
  isDataMasterAllDriversLoaded: false,
  isDataMasterDriversLoaded: false,
  isDataDriverDetailsLoaded: false,
  isDataMasterAllAssetsLoaded: false,
  isDataMasterAssetsLoaded: false,
  isDataAssignAssetsLoaded: false,
  isDataAssignAssetsJobLoaded: false,
  isDataMasterTechniciansLoaded: false,
  isDataTyreAssetsLoaded: false,
  isDataDriverAllocateLoaded: false,
  isDataAssetsAllocateLoaded: false,
  isDataAssetsTrailerLoaded: false,
  isDataMasterClaimProductLoaded: false,
};

const actions = {
  async fetchDataMasterDrivers({ commit, state }) {
    if (!state.isDataMasterDriversLoaded) {
      try {
        const response = await axios.get('api/webui/admins/drivers', {
          params: {
            is_enable: true,
            is_limit: false,
            status: 'Available',
          },
        });
        const sortedData = response.data.data.sort((a, b) => a.name.localeCompare(b.name));
        commit('setDataMasterDrivers', sortedData);
        commit('setDataMasterDriversLoaded', true);
        return response;
      } catch (error) {
        console.error('Error fetching driver data:', error);
        throw error;
      }
    }
  },

  async fetchDataMasterAllDrivers({ commit, state }) {
    if (!state.isDataMasterAllDriversLoaded) {
      try {
        const response = await axios.get('api/webui/admins/drivers', {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        const sortedData = response.data.data.sort((a, b) => a.name.localeCompare(b.name));
        commit('setDataMasterAllDrivers', sortedData);
        commit('setDataMasterAllDriversLoaded', true);
        return response;
      } catch (error) {
        console.error('Error fetching all driver data:', error);
        throw error;
      }
    }
  },

  async fetchDataDriverDetails({ commit, state }, id) {
    if (!state.isDataDriverDetailsLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/drivers/view/${id}`);
        commit('setDataDriverDetails', response.data.data);
        commit('setDataDriverDetailsLoaded', true);
        return response;
      } catch (error) {
        console.error('Error fetching driver detail data:', error);
        throw error;
      }
    }
  },

  async fetchDataMasterAssets({ commit, state }, vehicle_type) {
    if (!state.isDataMasterAssetsLoaded) {
      try {
        const params = {
          is_enable: true,
          is_limit: false,
          ...(vehicle_type && { search_by: 'vehicle_type', search_value: vehicle_type }),
        };
        const response = await axios.get('api/webui/admins/assets', { params });
        commit('setDataMasterAssets', response.data.data);
        commit('setDataMasterAssetsLoaded', true);
        return response;
      } catch (error) {
        console.error('Error fetching assets data:', error);
        throw error;
      }
    }
  },

  async fetchDataMasterAllAssets({ commit, state }) {
    if (!state.isDataMasterAllAssetsLoaded) {
      try {
        const response = await axios.get('api/webui/admins/assets', {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit('setDataMasterAllAssets', response.data.data);
        commit('setDataMasterAllAssetsLoaded', true);
        return response;
      } catch (error) {
        console.error('Error fetching all assets data:', error);
        throw error;
      }
    }
  },

  async fetchDataAssignAssets({ commit, state }, vehicle_type) {
    if (!state.isDataAssignAssetsLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/assets/assign/${vehicle_type}`);
        commit('setDataAssignAssets', response.data.data);
        commit('setDataAssignAssetsLoaded', true);
        return response;
      } catch (error) {
        console.error('Error fetching assets assign:', error);
        throw error;
      }
    }
  },

  async fetchDataAssignAssetsJob({ commit, state }, vehicle_type) {
    if (!state.isDataAssignAssetsJobLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/assets/job_assign/${vehicle_type}`);
        commit('setDataAssignAssetsJob', response.data.data);
        commit('setDataAssignAssetsJobLoaded', true);
        return response;
      } catch (error) {
        console.error('Error fetching assets job assign:', error);
        throw error;
      }
    }
  },

  async fetchDataMasterTechnicians({ commit, state }) {
    if (!state.isDataMasterTechniciansLoaded) {
      try {
        const response = await axios.get('api/webui/admins/technicians', {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit('setDataMasterTechnicians', response.data.data);
        commit('setDataMasterTechniciansLoaded', true);
        return response;
      } catch (error) {
        console.error('Error fetching technicians data:', error);
        throw error;
      }
    }
  },

  async fetchDataTyreAssets({ commit, state }, id) {
    if (!state.isDataTyreAssetsLoaded || state.currentAssetId !== id) {
      try {
        if (state.currentAssetId !== id) {
          commit("resetDataTyreAssets");
          commit("setDataTyreAssetsLoaded", false);
        }

        const response = await axios.get(`api/webui/admins/assets/tyre/${id}`);

        commit("setDataTyreAssets", response.data.data);
        commit("setCurrentAssetId", id);
        commit("setDataTyreAssetsLoaded", true);
        return response;
      } catch (error) {
        console.error("Error fetching tyre assets data:", error);
        throw error;
      }
    }
  },

  async fetchDriversAllocate({ commit, state }) {
    if (!state.isDataDriverAllocateLoaded) {
      try {
        const response = await axios.get('api/webui/admins/drivers/allocate');
        commit('setDriversAllocate', response.data.data);
        commit('setDataDriverAllocateLoaded', true);
        return response;
      } catch (error) {
        console.error('Error fetching driver allocate data:', error);
        throw error;
      }
    }
  },

  async fetchAssetsAllocate({ commit, state }) {
    if (!state.isDataAssetsAllocateLoaded) {
      try {
        const response = await axios.get('api/webui/admins/assets/allocate');
        commit('setAssetsAllocate', response.data.data);
        commit('setDataAssetsAllocateLoaded', true);
        return response;
      } catch (error) {
        console.error('Error fetching assets allocate data:', error);
        throw error;
      }
    }
  },

  async fetchAssetsTrailer({ commit, state }) {
    if (!state.isDataAssetsTrailerLoaded) {
      try {
        const response = await axios.get('api/webui/admins/assets/trailer');
        commit('setAssetsTrailer', response.data.data);
        commit('setDataAssetsTrailerLoaded', true);
        return response;
      } catch (error) {
        console.error('Error fetching assets trailer data:', error);
        throw error;
      }
    }
  },

  async fetchDataMasterClaimProduct({ commit, state }) {
    if (!state.isDataMasterClaimProductLoaded) {
      try {
        const response = await axios.get('api/webui/admins/driver/claims_product', {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit('setDataMasterClaimProduct', response.data.data);
        commit('setDataMasterClaimProductLoaded', true);
        return response;
      } catch (error) {
        console.error('Error fetching claim product data:', error);
        throw error;
      }
    }
  },
};

const mutations = {
  setDataMasterDrivers(state, data) {
    state.dataMasterDrivers = data;
  },
  setDataMasterAllDrivers(state, data) {
    state.dataMasterAllDrivers = data;
  },
  setDataDriverDetails(state, data) {
    state.dataDriverDetails = data;
  },
  setDataMasterAllAssets(state, data) {
    state.dataMasterAllAssets = data;
  },
  setDataMasterAssets(state, data) {
    state.dataMasterAssets = data;
  },
  setDataAssignAssets(state, data) {
    state.dataAssignAssets = data;
  },
  setDataAssignAssetsJob(state, data) {
    state.dataAssignAssetsJob = data;
  },
  setDataMasterTechnicians(state, data) {
    state.dataMasterTechnicians = data;
  },
  setDataTyreAssets(state, data) {
    state.dataTyreAssets = data;
  },
  setDriversAllocate(state, data) {
    state.dataDriverAllocate = data;
  },
  setAssetsAllocate(state, data) {
    state.dataAssetsAllocate = data;
  },
  setAssetsTrailer(state, data) {
    state.dataAssetsTrailer = data;
  },
  setDataMasterClaimProduct(state, data) {
    state.dataMasterClaimProduct = data;
  },

  // Mutation to set flags indicating data loading status
  setDataMasterDriversLoaded(state, status) {
    state.isDataMasterDriversLoaded = status;
  },
  setDataMasterAllDriversLoaded(state, status) {
    state.isDataMasterAllDriversLoaded = status;
  },
  setDataDriverDetailsLoaded(state, status) {
    state.isDataDriverDetailsLoaded = status;
  },
  setDataMasterAssetsLoaded(state, status) {
    state.isDataMasterAssetsLoaded = status;
  },
  setDataMasterAllAssetsLoaded(state, status) {
    state.isDataMasterAllAssetsLoaded = status;
  },
  setDataAssignAssetsLoaded(state, status) {
    state.isDataAssignAssetsLoaded = status;
  },
  setDataAssignAssetsJobLoaded(state, status) {
    state.isDataAssignAssetsJobLoaded = status;
  },
  setDataMasterTechniciansLoaded(state, status) {
    state.isDataMasterTechniciansLoaded = status;
  },
  setDataTyreAssetsLoaded(state, status) {
    state.isDataTyreAssetsLoaded = status;
  },
  setDataDriverAllocateLoaded(state, status) {
    state.isDataDriverAllocateLoaded = status;
  },
  setDataAssetsAllocateLoaded(state, status) {
    state.isDataAssetsAllocateLoaded = status;
  },
  setDataAssetsTrailerLoaded(state, status) {
    state.isDataAssetsTrailerLoaded = status;
  },
  setDataMasterClaimProductLoaded(state, status) {
    state.isDataMasterClaimProductLoaded = status;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
